// 1.0 引入封装的axios请求文件
import req from '../utils/request.js'
// 获取已审核新闻列表
export const getCheckedNewsInfo = para => req.post('/public/getCheckedNewsInfo', para, false)

// 新闻详情
export const newsDetail = para => req.post('/public/getNewsDetail?newsId=' + para, '', false)

// 招聘信息
export const getRecruitInfo = para => req.post('/public/getRecruitInfo', para, false)

// 招聘信息
export const createMessage = para => req.post('/public/createMessage', para, false)
